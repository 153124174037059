//Closure to encapsulate all JS
var UTILS = {
		attributelist: require('storm-attributelist'),
        throttle: require('lodash.throttle')
	},
    UI = (function(w, d) {
            'use strict';
            var LoadScript = function(src, cb) {
                    var t = document.createElement('script'),
                        s = document.getElementsByTagName('script')[0];
                    t.async = true;
                    t.src = src;
                    s.parentNode.insertBefore(t, s);
                    t.onload = cb;
                },
                Skip = require('storm-skip'),
                Outliner = require('storm-outliner'),
                FontFaceObserver = require('FontFaceObserver'),
                Picturefill = require('picturefill'),
                Toggler = require('storm-toggler'),
                Modal = require('storm-modal'),
                initFonts = function(){
                    var ffo = new FontFaceObserver('Neue Haas Grotesk W01', {})
                        .load()
                        .then(function () {
                            d.documentElement.className = document.documentElement.className.replace(/\bno-webfonts\b/,'');
                        }, function () {
                            console.log('Font is not available after waiting 3 seconds');
                        });
                },/*
                initAsyncExample = function(){
                    //detect and return if not needed
                    if(!(d.querySelector('.js-className'))) { return; }
                    //loaded async as required
                    load('/content/js/async/module.min.js', function(err){
                        if(err) {
                            return console.log(err);
                        }
                        Module.init('.js-className');
                    });
                },*/
                initTogglers = function() {
                    !!d.querySelector('.js-toggle') && Toggler.init('.js-toggle');
                    !!d.querySelector('.js-toggle-local') && Toggler.init('.js-toggle-local', {targetLocal: true});
                },
                // initMap = function(){
                //     //detect and return if not needed
                //     if(!(d.querySelector('.js-map'))) { return; }
                //     //loaded async as required
                //     LoadScript('/content/js/async/storm-google-map.min.js', function(){
                //         StormGoogleMap.init('.js-map', [
                //                 {
                //                     id: "Harrison Stevens",
                //                     title: "Harrison Stevens",
                //                     location : {
                //                         lat: 55.951101,
                //                         lng: -3.205885
                //                     },
                //                     url:'#',
                //                 }
                //             ],
                //             {
                //                 key:'AIzaSyCivFIoRvaYP2g6GJiMYOnwxPCkMbYB880'
                //             }
                //         );
                //     });
                // },
                initWorkMap = function(){
                    if(!d.querySelector('.js-work-map')){return;}
                        var http = new XMLHttpRequest();
                        http.open('GET','/locations',true);
                        http.setRequestHeader('Content-type','application/json');
                        http.onreadystatechange = function(){
                            if(http.readyState === 4 && http.status === 200){
                                //show message
                                var locations = JSON.parse(http.responseText);
                                LoadScript('/content/js/async/storm-google-map.min.js', function(){
                                StormGoogleMap.init('.js-work-map', locations,
                                {
                                    key:'AIzaSyCivFIoRvaYP2g6GJiMYOnwxPCkMbYB880'
                                });
                            });
                            }
                        };

                        http.send();
                },
                initTabAccordion = function(){
                    //detect and return if not needed
                    if(!(d.querySelector('.js-tabs-accordion'))) { return; }
                    //loaded async as required
                    LoadScript('/content/js/async/storm-tab-accordion.min.js', function(){
                          StormTabAccordion.init('.js-tabs-accordion');
                    });
                },
                initPolyfills = function(){
                    require('classlist-polyfill');
                    require('es6-object-assign').polyfill();
                    require('es6-promise').polyfill();
                },
                initAnchorNav = function(){
                    if(!d.querySelector('.js-nav')){ return; }
                    LoadScript('/content/js/async/anchor-nav.min.js', function(){
                            StormScrollSpy.init('.js-scrollspy');
                            StormScrollTo.init('.js-scrollto', {
                                speed: 600, //ms
                                offset:60
                            });
                      });
                },
                initEqualiser = function(){
                    if(!d.querySelector('.js-equal-height')){ return; }
                    LoadScript('/content/js/async/storm-equal-height.min.js', function(){
                            StormEqualHeight.init('.js-equal-height');
                      });
                },
                initModalGallery = function(){
                    if(!d.querySelector('.js-modal-gallery')){ return; }

                    LoadScript('/content/js/async/lightbox.min.js', function(){
                        var lightbox = new Lightbox(),
                            btn = d.querySelector('.js-modal-gallery__btn'),
                            firstImageLink = d.querySelector('[data-jslghtbx]');

                        lightbox.load({
                            preload:false
                        });

                        !!btn && btn.addEventListener('click', function(e){
                            e.preventDefault();
                            firstImageLink.click();
                        });
                    });

                    /*
                    LoadScript('/content/js/async/baguette-box.min.js', function(){
                        baguetteBox.run('.js-modal-gallery');
                    });
                    */
                },

                initScrollPoints = function(){
                    if(!d.querySelector('.js-scroll-point')){ return; }
                    LoadScript('/content/js/async/storm-scrollpoints.min.js', function(){
                            StormScrollpoints.init('.js-scroll-point', {
                                offset:"15%"
                            });


                      });
                },

                initSticky = function(){
                    if(!d.querySelector('.js-sticky')){ return; }
                    LoadScript('/content/js/async/storm-sticky.min.js', function(){
                            StormSticky.init('.js-sticky');
                      });
                },
                initWall = function(){
                    //detect and return if not needed
                    if(!(d.querySelector('.js-wall'))) { return; }
                    //loaded async as required
                    LoadScript('/content/js/async/storm-wall.min.js', function(){
                        StormWall.init();
                    });
                },
                initCarousel = function(){
                    //detect and return if not needed
                    if(!(d.querySelector('.js-peppermint'))) { return; }
                    //loaded async as required
                    LoadScript('/content/js/async/peppermint.min.js', function(){
                        var carousel = Peppermint(d.querySelector('.js-peppermint'), {
                                dots: true
                            });
                        //click `#right-arr` to go to the next slide
                        document.querySelector('.peppermint__nav--next').addEventListener('click', carousel.next, false);

                        //click `#left-arr` to go to the previous slide
                        document.querySelector('.peppermint__nav--previous').addEventListener('click', carousel.prev, false);
                    });

                },
                initFader = function(){
                    //detect and return if not needed
                    if(!d.querySelector('.js-wallop')) { return; }
                    //loaded async as required
                    LoadScript('/content/js/async/wallop.min.js', function(){
                        var fader = new Wallop(d.querySelector('.js-wallop')),
                            shouldAutoPlay = true,
                            autoplay = function(interval) {
                                var lastTime = 0;

                                function frame(timestamp) {
                                    var update = timestamp - lastTime >= interval;

                                    if (update) {
                                        fader.next();
                                        lastTime = timestamp;
                                    }

                                    !!shouldAutoPlay && requestAnimationFrame(frame);
                                }

                                requestAnimationFrame(frame);
                            },
                            paginationDots = Array.prototype.slice.call(document.querySelectorAll('.Wallop-dot'));
                            paginationDots.forEach(function (dotEl, index) {
                              dotEl.addEventListener('click', function() {
                                fader.goTo(index);
                                shouldAutoPlay = false;
                              });
                            });
                            fader.on('change', function(event) {
                                document.querySelector('.Wallop-dot--current').classList.remove('Wallop-dot--current');
                                paginationDots[event.detail.currentItemIndex].classList.add('Wallop-dot--current');
                            });

                            autoplay(5000);
                    });
                },
                initNewsletterForm = function(){
                    if(!d.querySelector('#newsletterform')){return;}
                    var form = d.querySelector('#newsletterform');
                    form.onsubmit = function(e){
                        e.preventDefault();
                        var http = new XMLHttpRequest();
                        var url = form.getAttribute('action');
                        http.open('POST',url,true);
                        http.setRequestHeader('Content-type','application/json');
                        http.onreadystatechange = function(){
                            if(http.readyState === 4 && http.status === 200){
                                //show message
                                var message = JSON.parse(http.responseText);
                                var messageElement = form.querySelector('#newsletter-message');
                                messageElement.innerHTML = message.message;
                                messageElement.style.display = '';
                                messageElement.style.color = message.color;
                            }
                        };

                        var data = {'EmailAddress':form.querySelector('#email').value}

                        http.send(JSON.stringify(data));
                    }
                },
                init = function() {
                    initPolyfills();
                    initFonts();
                    initTogglers();
                    // initMap();
                    initTabAccordion();
                    initAnchorNav();
                    initModalGallery();
                    initEqualiser();
                    initCarousel();
                    initFader();
                    //ScrollPoints.init('.js-scroll-point');
                    Modal.init('.js-modal');
                    initSticky();
                    //initAsyncExample();
                    initNewsletterForm();
                    initWorkMap();
                    require('lazysizes');
                    window.lazySizesConfig = window.lazySizesConfig || {};
                    lazySizesConfig.expand = 0;
                },
                loaded = function(){
                    window.setTimeout(initWall, 260);
                    initScrollPoints();
                };

            //Interface with/entry point to site JS
            return {
                init: init,
				loaded: loaded
            };

        }(window, document, undefined));

global.STORM = {
    UI: UI,
    UTILS: UTILS
};

//Cut the mustard
//Don't run any JS if the browser can't handle it
if('addEventListener' in window) window.addEventListener('DOMContentLoaded', STORM.UI.init, false);
if('addEventListener' in window) window.addEventListener('load', STORM.UI.loaded, false);
